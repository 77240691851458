import { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { updateData } from '../utils/constants';
import { toast } from 'react-toastify';

function MyProfile() {
    const [user, setUser] = useState({});
    const [helpText, setHelpText] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [formView, setFormView] = useState(false);    

    const handleInputChange = (e)=> {
        const {name, value} = e.target;
        if((name === 'password') || (name === 'c_password')){
            setHelpText('');
        }
        setUser((prevObj)=> ({
            ...prevObj,
            [name] : value
        }));
    }

    const handleSubmit = (e)=> {
        e.preventDefault();        
        var userObj = {...user};
        if(!userObj.password || userObj?.password !== userObj?.c_password || userObj?.password?.length===0){
            setHelpText("Passwords doesn't match.!");
            return false;
        }else{
            setHelpText('');
            updateData('/api/current-user', userObj)
                .then((response) => {                
                toast.success(response.message);       
                getCurrentUser();
            });
        }
        
    }

    const getCurrentUser = async()=>{
        try {
          const response = await fetch('/api/current-user');
          const json = await response.json();          
          setUser(json.length?json[0]:{...user});
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
    }

    useEffect(()=>{
        getCurrentUser();
    },[])

    return(
        <div className='h-100 row justify-content-center align-items-center'>
             {
                    !isLoading &&
                    !formView?(
                        <Card className='col-12 col-sm-8 col-md-6'>
                            <Card.Header>
                                <h5 className='mb-0 my-2'> User Information</h5>
                            </Card.Header>                        
                            <Card.Body>                                    
                                <div className='my-3'>
                                    <label className='fw-light pb-2'>Username</label>
                                    <h6 className='px-2'>{user?.username}</h6>
                                </div>
                                <div className='my-3'>
                                    <label className='fw-light pb-2'>Email</label>
                                    <h6 className='px-2'>{user?.email}</h6>
                                </div>                          
                                <div className='my-3'>
                                    <label className='fw-light pb-2'>Role</label>
                                    <h6 className='px-2 text-uppercase'>{user?.role_name}</h6>
                                </div> 
                                <div className='my-3'>
                                    <label className='fw-light pb-2'>Phone</label>
                                    <h6 className='px-2'>{user?.phone}</h6>
                                </div>                          
                                <div className='my-3'>
                                    <label className='fw-light pb-2'>Status</label>
                                    <h6 className='px-2'>{user?.is_active?'Active':'In active'}</h6>
                                </div>                          
                                <div className='my-3 pt-2'>
                                    <button type="button" className='btn btn-outline-secondary' onClick={()=> setFormView(true)}>
                                        <FontAwesomeIcon icon={faPencilAlt} className='pe-2' />
                                        Edit
                                    </button>
                                </div>                          
                            </Card.Body>                              
                        </Card>
                    ):(
                        <Card className='col-12 col-sm-8 col-md-6'>
                            <Card.Header>
                                <h5 className='mb-0 my-2'> Edit User details</h5>
                            </Card.Header>                        
                            <Card.Body>
                                <Form onSubmit={handleSubmit} autoComplete='off'>
                                    <div className='my-3'>
                                        <label className='fw-light pb-2'>Username*</label>
                                        <Form.Control name='username' type='text' className='shadow-none' value={user?.username} maxLength={100} onChange={handleInputChange} required />
                                    </div>
                                    <div className='my-3'>
                                        <label className='fw-light pb-2'>Email*</label>
                                        <Form.Control name='email' type='email' className='shadow-none' value={user?.email} maxLength={255} onChange={handleInputChange} required />
                                    </div>
                                    <div className='my-3'>
                                        <label className='fw-light pb-2'>Phone</label>
                                        <Form.Control name='phone' type='text' className='shadow-none' value={user?.phone} maxLength={20} onChange={handleInputChange} />
                                    </div>                          
                                    <div className='my-3'>
                                        <label className='fw-light pb-2'>Password*</label>
                                        <Form.Control name='password' type='password' className='shadow-none' value={user?.password} maxLength={16} onChange={handleInputChange} required />
                                    </div>
                                    <div className='my-3'>
                                        <label className='fw-light pb-2'>Confirm Password*</label>
                                        <Form.Control name='c_password' type='password' className='shadow-none' value={user?.c_password} maxLength={16} onChange={handleInputChange} required />
                                    </div>                                    
                                    {
                                        <p className='text-danger pb-1'>{helpText}</p>
                                    }                                            
                                    <div className='my-3'>                                    
                                        <button type="submit" className='btn btn-primary me-2'>Save</button>                                    
                                        <button type="button" className='btn btn-outline-secondary ms-2' onClick={()=> {
                                            setHelpText('');
                                            setFormView(false);                                        
                                        }}>Cancel</button>
                                    </div>       
                                </Form>                                                                                   
                            </Card.Body>                              
                        </Card> 
                    )
            }
            
        </div>
    );
}

export { MyProfile };