import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { TopBar, SideNav } from './partials/layouts';
import { Login } from './screens/login';
import { Dashboard } from './screens/dashboard';
import { Vendors } from './screens/vendors';
import { InstallerCompanies } from './screens/installer_companies';
import { ServiceCenters } from './screens/service_center';
import { Warehouses } from './screens/warehouses';
import { BOS } from './screens/bos';
import { Projects } from './screens/projects';
import { ConsumerList } from './screens/consumer_list';
import { OrderDetails } from './screens/order_details';
import { InvoiceUpload } from './screens/invoice_upload';
import { SiteSurvey } from './screens/site_survey';
import { OrderStatusUpdate } from './screens/order_status_update';
import { OrderAttachments } from './screens/order_attachments';
import { AssetMapping } from './screens/asset_mapping';
import { Products } from './screens/products';
import { Stocks } from './screens/stocks';
import { Users } from './screens/users';
import { Pumps } from './screens/pumps';
import { Motors } from './screens/motors';
import { SolarPV } from './screens/solar_pv';
import { PumpControllers } from './screens/pump_controllers';
import { MyProfile } from './screens/profile';
import { AuthContext , ProjectContext } from './utils/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgeGroupOrders } from './screens/ageing_report';


function App() {
  const [sidebarExpand, setSidebarExpand] = useState(true);

  const setCurrentProject = (selectedProject) => {    
    setProject({
      activeProjectId: selectedProject.activeProjectId,
      monthInterval  : selectedProject.monthInterval,
      setCurrentProject: project.setCurrentProject
    });
  };

  const [project, setProject] = useState({
    activeProjectId: "",
    monthInterval  : 0,
    setCurrentProject: setCurrentProject
  });

  const collapseSideNav = ()=> {
    setSidebarExpand(!sidebarExpand);
  }
  let navigate = useNavigate();

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        userToken = await sessionStorage.getItem('userToken');
      } catch (e) {
        console.log(e);
      } finally {
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      }
    };
    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        fetch('/api/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'username' :data.username,
            'password' :data.password
          })
        })
        .then(response => response.json())
        .then(result => {
          sessionStorage.setItem("userName", result.username);
          sessionStorage.setItem("userToken", result.email);
          dispatch({ type: 'SIGN_IN', token: result.email });          
          navigate("/");
        })
        .catch((e) => {
          toast.error(e);
          console.error('Error:', e);
        });
      },
      signOut: async() => {
        sessionStorage.removeItem("userToken");
        setCurrentProject({activeProjectId: "", monthInterval: 0});
        dispatch({ type: 'SIGN_OUT', token: null});
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SessionStorage`
        // In the example, we'll use a dummy token
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
      },
    }),
    []
  );

  return (
    <AuthContext.Provider value={authContext}>
      <ProjectContext.Provider value={project}>
        <div className="App">
          {state.isLoading?(
            <Routes>
                <Route path="/login" element={<Login />} />
            </Routes>
          ):state.userToken===null?(
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Dashboard />} />
            </Routes>
          ):(
            <div id="main-body">
              <TopBar collapseSideNav={collapseSideNav} sidebarExpand={sidebarExpand} />
              <section id="FullPannel">
                <SideNav collapseSideNav={collapseSideNav} sidebarExpand={sidebarExpand} />
                <section id="RightPannel">
                  <main id="Content" className="container-fluid h-100">
                    <Routes>
                      <>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/consumer-list" element={<ConsumerList />} />
                        <Route path="/consumer-list/:orderId" element={<OrderDetails />} />
                        <Route path="/invoice-upload" element={<InvoiceUpload />} />
                        <Route path="/site-survey" element={<SiteSurvey />} />
                        <Route path="/order-attachments" element={<OrderAttachments />} />
                        <Route path="/order-status-update" element={<OrderStatusUpdate />} />
                        <Route path="/asset-mapping" element={<AssetMapping />} />
                        <Route path="/bos" element={<BOS />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/stocks" element={<Stocks />} />
                        <Route path="/warehouses" element={<Warehouses />} />
                        <Route path="/vendors" element={<Vendors />} />
                        <Route path="/installer-companies" element={<InstallerCompanies />} />
                        <Route path="/service-center" element={<ServiceCenters />} />
                        <Route path="/pumps" element={<Pumps />} />
                        <Route path="/motors" element={<Motors />} />
                        <Route path="/pump-controllers" element={<PumpControllers />} />
                        <Route path="/solar-pv" element={<SolarPV />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/profile" element={<MyProfile />} />
                        <Route path="/age-group-orders/:projectId/:ageGroup" element={<AgeGroupOrders />} />
                      </>
                    </Routes>
                    <ToastContainer />
                  </main>
                </section>
              </section>
            </div>
          )}
        </div>
      </ProjectContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
